import React, { useState } from "react";
import PageContainer from "../components/pageContainer/PageContainer";
import StyledInput from "../components/styledInput/StyledInput";
import Button from "../components/button";
import { validateEmail, validateValidationCode } from "../utils/validator";
import ApiDatabase from "../server";
import { useNavigate } from "react-router-dom";
import { WorkfullyLogo } from "../assets/icons";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [validationCodeError, setValidationCodeError] = useState(false);
  const [loadingCodeSent, setLoadingCodeSent] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [loadingCodeValidation, setLoadingCodeValidation] = useState(false);
  const navigate = useNavigate();

  const handleSubmitCodeSent = () => {
    if (email) {
      if (!validateEmail(email)) {
        setEmailError("Veuillez entrer une adresse e-mail valide.");
      } else {
        setEmailError(false);
        setLoadingCodeSent(true);
        ApiDatabase.postUserLostPassword(
          { email },
          (data) => {
            setLoadingCodeSent(false);
            setIsCodeSent(true);
          },
          (err) => {
            console.log(err);
            setLoadingCodeSent(false);
          },
        );
      }
    } else {
      if (!email) setEmailError("Veuillez entrer une adresse e-mail.");
      else setEmailError(false);
    }
  };

  const handleSubmitCodeValidation = () => {
    if (validationCode) {
      setValidationCodeError(false);
      if (!validateValidationCode(validationCode)) {
        setValidationCodeError("Veuillez entrer un code valide.");
      } else {
        setValidationCodeError(false);
        setLoadingCodeValidation(true);
        ApiDatabase.postUserLostPasswordValidation(
          { email, code: validationCode },
          (data) => {
            setLoadingCodeValidation(false);
            navigate("/forgot-password-change");
          },
          (err) => {
            console.log(err);
            if (err.response.status === 401) {
              setValidationCodeError("Le code est incorrect.");
            }
            setLoadingCodeValidation(false);
          },
        );
      }
    } else {
      setValidationCodeError("Veuillez entrer un code.");
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          <div className={"flex flex-col gap-1"}>
            <div className={"text-3xl font-semibold"}>
              {isCodeSent
                ? "Validation du code mot de passe oublié"
                : "Mot de passe oublié"}
            </div>
            {isCodeSent && (
              <div className={"text-sm text-gray-600 flex flex-col gap-2"}>
                <div>
                  Si vous avez un compte associé à {email}, vous devriez
                  recevoir un e-mail de réinitialisation dans les prochaines
                  minutes.
                </div>
                <div>
                  Veuillez vérifier votre boîte de réception, y compris le
                  dossier des courriers indésirables. Si vous ne recevez pas
                  l'e-mail, veuillez vérifier que l'adresse e-mail saisie est
                  correcte et réessayez.
                </div>
              </div>
            )}
          </div>
          {isCodeSent ? (
            <StyledInput
              type={"number"}
              label={"Validation du code :"}
              value={validationCode}
              onChange={(e) => {
                setValidationCode(e.target.value);
              }}
              error={validationCodeError}
            />
          ) : (
            <StyledInput
              type={"text"}
              label={"E-mail"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={emailError}
            />
          )}
          {isCodeSent ? (
            <>
              {loadingCodeValidation ? (
                <Button color={"DISABLED"} size={"FULL"}>
                  Confirmation
                </Button>
              ) : (
                <Button
                  color={"PRIMARY"}
                  size={"FULL"}
                  onClick={handleSubmitCodeValidation}
                >
                  Confirmer
                </Button>
              )}
            </>
          ) : (
            <>
              {loadingCodeSent ? (
                <Button color={"DISABLED"} size={"FULL"}>
                  Envoie du lien ...
                </Button>
              ) : (
                <Button
                  color={"PRIMARY"}
                  size={"FULL"}
                  onClick={handleSubmitCodeSent}
                >
                  M'envoyer un lien
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default ForgotPassword;
