import PageContainer from "../components/pageContainer/PageContainer";
import StyledInput from "../components/styledInput/StyledInput";
import { useState } from "react";
import useTitle from "../hooks/TitleManager";
import Button from "../components/button";
import { NavLink } from "react-router-dom";
import { validateEmail } from "../utils/validator";
import ApiDatabase from "../server/index";
import { WarningCircle, WorkfullyLogo } from "../assets/icons";

const Login = () => {
  useTitle({ title: "Se connecter" });

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (email && password) {
      if (!validateEmail(email)) {
        setEmailError("Veuillez entrer une adresse e-mail valide.");
      } else {
        setEmailError(false);
        setLoading(true);
        ApiDatabase.postUserLogin(
          { email, password, rememberMe },
          (data) => {
            setLoading(false);
            const token = localStorage.getItem("token");
            window.location.href =
              process.env.REACT_APP_COMPANY_URL + "?token=" + token;
          },
          (err) => {
            if (err.response?.status === 401) {
              setErrorRegister("Votre email ou mot de passe est incorrect.");
            }
            setLoading(false);
            console.log(err);
          },
        );
      }
    } else {
      if (!email) setEmailError("Veuillez entrer une adresse e-mail.");
      else setEmailError(false);
      if (!password) setPasswordError("Veuillez entrer un mot de passe.");
      else setPasswordError(false);
    }
  };

  return (
    <PageContainer>
      <div className={"flex h-full bg-workfully-700 p-2 overflow-auto"}>
        <div
          className={
            "w-full max-w-[448px] m-auto border rounded-xl bg-white p-8 flex flex-col gap-6"
          }
        >
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
          <div className={""}>
            <div className={"text-3xl font-semibold"}>Se connecter</div>
            <div className={"text-sm text-gray-600"}>
              Connectez-vous avec votre e-mail et votre mot de passe Workflow.
            </div>
          </div>
          {errorRegister && (
            <div
              className={
                "w-full text-red-500 text-sm flex justify-center gap-1"
              }
            >
              <WarningCircle wh={20} color={"#EF4444"} />
              {errorRegister}
            </div>
          )}
          <StyledInput
            type={"text"}
            label={"E-mail"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={emailError}
          />
          <StyledInput
            type={"password"}
            label={"Mot de passe"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            error={passwordError}
          />
          <div className={"flex justify-between items-center"}>
            <StyledInput
              type={"checkbox"}
              label={"Rester connecté(e)"}
              checked={rememberMe}
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
            />
            <NavLink
              to={"/forgot-password"}
              className={"text-workfully-700 underline"}
            >
              Mot de passe oublié ?
            </NavLink>
          </div>
          {loading ? (
            <Button color={"DISABLED"} size={"FULL"}>
              Connexion ...
            </Button>
          ) : (
            <Button color={"PRIMARY"} size={"FULL"} onClick={handleSubmit}>
              Connexion
            </Button>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default Login;
