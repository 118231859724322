const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validateValidationCode = (number) => {
  //need to be 6 numbers only
  const numberRegex = /^[0-9]{6}$/;
  return numberRegex.test(number);
};

export { validateEmail, validateValidationCode };
