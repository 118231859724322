import { Route, Routes } from "react-router-dom";
import Layout from "../pages/Layout";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import PageNotFound from "../pages/PageNotFound";
import ForgotPasswordChange from "../pages/ForgotPasswordChange";
import Invitation from "../pages/Invitation";

const ConnectionRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={""} element={<Login />} />
          <Route path={"forgot-password"} element={<ForgotPassword />} />
          <Route
            path={"forgot-password-change"}
            element={<ForgotPasswordChange />}
          />
          <Route path={"invitation/:id"} element={<Invitation />} />
          <Route path={"*"} element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default ConnectionRoutes;
