import { WorkfullyLogo } from "../../assets/icons";
import { NavLink } from "react-router-dom";

const NavbarLogin = () => {
  return (
    <nav className={"py-4 px-6"}>
      <div className={"flex justify-between items-center"}>
        <NavLink to={"/"}>
          <WorkfullyLogo w={130} h={30} />
        </NavLink>
        <div></div>
      </div>
    </nav>
  );
};

export default NavbarLogin;
