import axios from "axios";

const getUserInfo = async () => {
  try {
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), 1000),
    );

    const ipInfoPromise = axios.get(`https://ipapi.co/json/`);

    const response = await Promise.race([timeoutPromise, ipInfoPromise]);

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return {};
  }
};

const storeToken = (token) => {
  localStorage.setItem("token", token);
};

const setAuthHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const updateBatteryLevel = () => {
  const batteryLevel = localStorage.getItem("battery");
  if (batteryLevel) {
    return batteryLevel;
  }
  return null;
};

const makeRequest = async (url, method, data, resolve, reject) => {
  try {
    const userInfo = await getUserInfo();

    const batteryLevel = updateBatteryLevel();

    setAuthHeader();

    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      withCredentials: true,
      headers: {
        "X-User-Info": JSON.stringify(userInfo),
        "X-Battery-Level": batteryLevel,
      },
      data,
    });

    if (response.data.errors) {
      reject(response.data.errors);
    } else {
      // Stocker le token reçu côté client
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(" ")[1];
        storeToken(token);
      }

      resolve(response.data);
    }
  } catch (error) {
    reject(error);
  }
};

const ApiDatabase = {
  postUserLogin: (data, resolve, reject) =>
    makeRequest("auth/login", "post", data, resolve, reject),
  postUserLostPassword: (data, resolve, reject) =>
    makeRequest("auth/lost-password", "post", data, resolve, reject),
  postUserLostPasswordValidation: (data, resolve, reject) =>
    makeRequest("auth/lost-password/validation", "post", data, resolve, reject),
  postUserLostPasswordChange: (data, resolve, reject) =>
    makeRequest("auth/lost-password/change", "post", data, resolve, reject),
  getEmployeeById: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee`, "get", data, resolve, reject),
  putPasswordEmployee: (data, resolve, reject) =>
    makeRequest(`bigCompany/employee/password`, "put", data, resolve, reject),
};

export default ApiDatabase;
