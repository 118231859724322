import NavbarLogin from "../navbars/navbarLogin";

const PageContainer = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <div>
        <NavbarLogin />
      </div>
      <div className={"flex-1 h-full"}>{children}</div>
    </div>
  );
};

export default PageContainer;
