import {createContext} from "react";

const roadContext = createContext({})

const ContextProvider = ({children}) => {

    return (
        <roadContext.Provider value={{

        }}>
            {children}
        </roadContext.Provider>
    )
}

export {ContextProvider, roadContext}